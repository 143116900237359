import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58e2c74c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "artwork-page-info" }
const _hoisted_2 = { class: "artwork-page-info-artist mb-5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "artwork-page-info-description p-5 mb-4"
}
const _hoisted_5 = { class: "artwork-page-info-description-container" }
const _hoisted_6 = { class: "artwork-page-info-artists p-5" }
const _hoisted_7 = {
  class: "artwork-page-info-artists-container",
  tabindex: "0"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_9 = {
  key: 0,
  class: "is-flex is-justify-content-center"
}
const _hoisted_10 = { class: "artwork-page-info-tags p-5" }
const _hoisted_11 = {
  key: 0,
  class: "is-flex is-flex-wrap-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_TitleYearLabel = _resolveComponent("TitleYearLabel")!
  const _component_Label = _resolveComponent("Label")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_LabelledText = _resolveComponent("LabelledText")!
  const _component_Date = _resolveComponent("Date")!
  const _component_Taxonomies = _resolveComponent("Taxonomies")!
  const _component_Taxonomy = _resolveComponent("Taxonomy")!
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Copyright = _resolveComponent("Copyright")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Label, {
        size: "bg",
        subSize: "bg"
      }, {
        title: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.getArtistsNames()), 1),
          (_ctx.loading && !_ctx.artists.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Spinner, { size: "sm" })
              ]))
            : _createCommentVNode("", true)
        ]),
        subtitle: _withCtx(() => [
          _createElementVNode("h2", null, [
            _createVNode(_component_TitleYearLabel, { artwork: _ctx.artwork }, null, 8, ["artwork"])
          ])
        ]),
        _: 1
      })
    ]),
    (_ctx.hasDescription(_ctx.artwork))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Translation, {
              data: _ctx.artwork,
              property: "description"
            }, null, 8, ["data"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("artist.about-artist")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artists, (artist) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "artist-item",
            key: artist['id']
          }, [
            _createVNode(_component_LabelledText, { row: true }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artist.full-name")) + ":", 1)
              ]),
              subtitle: _withCtx(() => [
                _createTextVNode(_toDisplayString(artist.fullName), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_LabelledText, { row: true }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artist.year-of-birth")) + ":", 1)
              ]),
              subtitle: _withCtx(() => [
                _createVNode(_component_Date, {
                  format: "slash",
                  date: artist.birthDate
                }, null, 8, ["date"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_LabelledText, { row: true }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artist.year-of-death")) + ":", 1)
              ]),
              subtitle: _withCtx(() => [
                _createVNode(_component_Date, {
                  format: "slash",
                  date: artist.deathDate
                }, null, 8, ["date"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_LabelledText, { row: true }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artist.nationality")) + ":", 1)
              ]),
              subtitle: _withCtx(() => [
                _createVNode(_component_Taxonomies, {
                  type: "nationalities",
                  taxonomies: artist.nationalities
                }, null, 8, ["taxonomies"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_LabelledText, { row: true }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artist.gender")) + ":", 1)
              ]),
              subtitle: _withCtx(() => [
                _createVNode(_component_Taxonomy, {
                  type: "genders",
                  id: artist.gender
                }, null, 8, ["id"])
              ]),
              _: 2
            }, 1024),
            (_ctx.hasDescription(artist))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_Translation, {
                    data: artist,
                    property: "description"
                  }, null, 8, ["data"])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      (_ctx.loading && !_ctx.artists.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_Spinner, { size: "sm" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artists, (artist) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "mb-5 is-block has-text-centered",
          key: artist['@id'],
          to: { name: 'Search', query: { creatorsNames: artist.fullName } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Block, { class: "px-4 py-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("artwork.see-all-artworks", { fullName: artist.fullName })), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128)),
      (_ctx.artwork.tags && _ctx.artwork.tags.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artwork.tags, (tag) => {
              return (_openBlock(), _createElementBlock("div", { key: tag }, [
                (tag[_ctx.locale + 'Translation'])
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: 'Search', query: { q: _ctx.getTagTranslation(tag) } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Block, { class: "mr-3 mb-3 px-4 py-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Translation, { data: tag }, null, 8, ["data"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Copyright, {
        class: "mt-2",
        artwork: _ctx.artwork,
        artists: _ctx.artists
      }, null, 8, ["artwork", "artists"])
    ])
  ]))
}