import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Taxonomy = _resolveComponent("Taxonomy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taxonomies, (taxonomy, i) => {
      return (_openBlock(), _createElementBlock("span", { key: taxonomy }, [
        _createVNode(_component_Taxonomy, {
          type: _ctx.type,
          id: taxonomy
        }, null, 8, ["type", "id"]),
        (_ctx.taxonomies.length > 1 && i < _ctx.taxonomies.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, ", "))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (!_ctx.taxonomies.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
      : _createCommentVNode("", true)
  ], 64))
}