import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f7cbd48"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArtworkPageSingleVideoContent = _resolveComponent("ArtworkPageSingleVideoContent")!

  return (_ctx.contents.length)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: "artwork-page-video-contents",
        "aria-label": _ctx.$t('artwork.internal-content')
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item['@id'],
            class: _normalizeClass(["artwork-page-video-contents-container", [
                { 'half-container': !item.fullWidth },
                { penultimate: _ctx.contents?.length > 1 ? index === _ctx.contents?.length - 2 : false },
            ]])
          }, [
            _createVNode(_component_ArtworkPageSingleVideoContent, {
              content: item,
              placement: _ctx.placements[index]
            }, null, 8, ["content", "placement"])
          ], 2))
        }), 128))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}