import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-511484d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "artwork-page-related-collections pt-6",
  "aria-labelledby": "artwork-page-related-collections-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { id: "artwork-page-related-collections-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionSlider = _resolveComponent("CollectionSlider")!

  return (_ctx.entities?.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("artwork.related-collections")), 1),
          _createVNode(_component_CollectionSlider, { entities: _ctx.entities }, null, 8, ["entities"])
        ])
      ]))
    : _createCommentVNode("", true)
}