
import { defineComponent, PropType, ref } from "vue";
import { ArtworkDto, ArtistDto, TranslatableEntity } from "@kunsten/core";
import { useArtists } from "@kunsten/core";
import { useI18n } from "vue-i18n";
import Label from "../../../shared/components/Label.vue";
import LabelledText from "../../../shared/components/LabelledText.vue";
import Date from "../../../shared/components/Date.vue";
import Translation from "../../../shared/components/Translation.vue";
import Taxonomy from "../../../shared/components/Taxonomy.vue";
import Taxonomies from "../../../shared/components/Taxonomies.vue";
import Spinner from "../../../shared/components/Spinner.vue";
import Block from "../../../shared/components/Block.vue";
import getEntityTranslation from "../../entity/get-entity-translation";
import TitleYearLabel from "../../../shared/components/TitleYearLabel.vue";
import Copyright from "../../../shared/components/Copyright.vue";

export default defineComponent({
    name: "ArtworkPageInfo",
    components: {
        Date,
        Label,
        LabelledText,
        Taxonomy,
        Taxonomies,
        Translation,
        Spinner,
        Block,
        TitleYearLabel,
        Copyright,
    },
    props: {
        artwork: {
            type: Object as PropType<ArtworkDto>,
            required: true,
        },
    },
    setup(props) {
        const loading = ref(true);
        const artists = ref([] as ArtistDto[]);

        const { getMultipleArtists } = useArtists();
        const { locale } = useI18n();

        getMultipleArtists(props.artwork.creators).then(
            (res) => {
                artists.value = res.map((r) => r.data);
                loading.value = false;
            },
            () => {
                loading.value = false;
            }
        );

        const getArtistsNames = () => {
            if (!artists.value.length) return "";
            const names = artists.value.map((a) => a.fullName);
            return names.length > 1 ? names.join(", ") : names[0];
        };

        const hasDescription = (entity: ArtworkDto | ArtistDto): boolean => {
            const property = (locale.value + "Translation") as "daTranslation" | "enTranslation";
            const description: string = entity[property]?.description;
            return !!description;
        };

        return { getArtistsNames, hasDescription, locale, artists, loading };
    },
    methods: {
        getTagTranslation(tag: TranslatableEntity<string>) {
            return getEntityTranslation<TranslatableEntity<string>>(tag, "");
        },
    },
});
