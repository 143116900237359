
import { defineComponent, PropType } from "vue";
import { EsArtworkDto, useArtworks } from "@kunsten/core";
import ArtworkSlider from "../../../shared/components/ArtworkSlider.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "ArtworkPageRelated",
    components: { ArtworkSlider },
    props: {
        artworks: {
            type: Array as PropType<string[]>,
            required: true,
        },
    },
    data() {
        return {
            relatedArtworks: [] as EsArtworkDto[],
        };
    },
    created() {
        if (this.ids.length) {
            this.getMultipleEsArtworks(this.ids, this.locale).then((res) => {
                this.relatedArtworks = res.map((r) => r.data);
            });
        }
    },
    setup() {
        const { locale } = useI18n();
        const { getMultipleEsArtworks } = useArtworks();

        return { getMultipleEsArtworks, locale };
    },
    computed: {
        ids(): string[] {
            return this.artworks
                .map((iri) => {
                    const parts = iri.split("/");
                    if (parts.length === 4) return parts[3];
                    return "";
                })
                .filter((id) => !!id);
        },
    },
});
