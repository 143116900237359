
import { defineComponent, PropType } from "vue";
import { KunstenDateFormat, useFormattedDate } from "@kunsten/core";
import { KunstenDate } from "@kunsten/core";

export default defineComponent({
    name: "Date",
    props: {
        date: {
            type: Object as PropType<KunstenDate>,
            required: true,
        },
        format: {
            type: String as PropType<KunstenDateFormat>,
            default: "default",
        },
        period: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { formattedDate } = useFormattedDate(props.date, props.format, props.period);

        return { formattedDate };
    },
});
