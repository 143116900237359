
import { useExhibitionsResource } from "@kunsten/core";
import { defineComponent } from "vue";
import ExhibitionBlock from "../../../shared/components/ExhibitionBlock.vue";

export default defineComponent({
    name: "ArtworkPageExhibitions",
    components: { ExhibitionBlock },
    props: {
        artworkId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { entities } = useExhibitionsResource({
            page: "1",
            pagination: "false",
            "order[startDate]": "desc",
            "artworks.id": props.artworkId,
        });

        return { entities };
    },
});
