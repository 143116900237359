
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore, mapGetters } from "vuex";
import { useTaxonomies } from "@kunsten/core";

export default defineComponent({
    name: "Taxonomy",
    props: {
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const { locale } = useI18n();
        const { getTaxonomies } = useTaxonomies();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = ref<Record<string, any>>({});

        data.value = store.getters.getTaxonomyById(props.id);

        if (!data.value) {
            store.watch(
                (state) => state.loading,
                (loading) => {
                    // global loading is ongoing
                    if (loading) return;
                    // get data from store
                    data.value = store.getters.getTaxonomyById(props.id);
                    // if there is still no data - fetch from api
                    if (!data.value) {
                        getTaxonomies(props.type, { page: "1", pagination: "false" }).then((res) => {
                            const taxonomies = res.data ? res.data["hydra:member"] : [];
                            store.dispatch("addTaxonomies", taxonomies);
                            data.value = store.getters.getTaxonomyById(props.id);
                        });
                    }
                },
                { immediate: true }
            );
        }

        return { locale, data };
    },
    computed: {
        ...mapGetters(["getTaxonomyById", "isLoading"]),
    },
});
