
import { ExhibitionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import Translation from "./Translation.vue";
import ExhibitionBlockContent from "./ExhibitionBlockContent.vue";

export default defineComponent({
    name: "ExhibitionBlock",
    components: { Translation, ExhibitionBlockContent },
    props: {
        entity: {
            type: Object as PropType<ExhibitionDto>,
            required: true,
        },
    },
});
