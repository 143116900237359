<template>
    <div class="labelled-text" :class="[{ row: row }, { tiny: tiny }]">
        <div class="labelled-text-title">
            <slot name="title"></slot>
        </div>
        <div class="labelled-text-subtitle">
            <slot name="subtitle"></slot>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "LabelledText",
    props: {
        row: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";

.labelled-text {
    display: flex;
    flex-direction: column;
    &-title {
        flex: 1;
        opacity: 0.8;
        font-size: 0.9375rem;
        font-weight: bold;
        color: $kun-white-alt;
        margin-right: 0.25rem;
        @include print-black();
    }
    &-subtitle {
        flex: 1;
        font-size: 0.9375rem;
        font-weight: 300;
        color: $kun-white;
        @include print-black();
    }
    &.tiny {
        .labelled-text-title {
            font-size: 0.8125rem;
        }
        .labelled-text-subtitle {
            font-size: 0.8125rem;
        }
    }
    @include desktop {
        flex-direction: row;
        &:not(.tiny) {
            .labelled-text-subtitle {
                flex: 2;
            }
        }
    }
    &.row {
        flex-direction: row !important;
    }
}
</style>
