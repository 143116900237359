
import { ExhibitionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import IconText from "./IconText.vue";
import EsDate from "./EsDate.vue";

export default defineComponent({
    name: "ExhibitionBlockContent",
    components: { IconText, EsDate },
    props: {
        entity: {
            type: Object as PropType<ExhibitionDto>,
        },
    },
    setup() {
        return {};
    },
});
