import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionBlock = _resolveComponent("CollectionBlock")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createBlock(_component_Slider, { entities: _ctx.entities }, {
    default: _withCtx(({ entity }) => [
      _createVNode(_component_CollectionBlock, { collection: entity }, null, 8, ["collection"])
    ]),
    _: 1
  }, 8, ["entities"]))
}