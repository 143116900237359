import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bed19482"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content-title mb-6"
}
const _hoisted_2 = {
  key: 1,
  class: "content-image mb-6"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "content-video mb-6"
}
const _hoisted_5 = {
  key: 3,
  class: "content-text mb-6"
}
const _hoisted_6 = {
  key: 4,
  class: "content-link mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_VideoContainer = _resolveComponent("VideoContainer")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["artwork-page-single-video-content mt-6", [{ half: !_ctx.content.fullWidth }, _ctx.placement]])
  }, [
    (_ctx.content[_ctx.locale + 'Translation'].title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", null, [
            _createVNode(_component_TranslationAlt, {
              data: _ctx.content,
              property: "title"
            }, null, 8, ["data"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.content.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("figure", null, [
            _createElementVNode("img", {
              src: _ctx.content.image + '/download',
              alt: ""
            }, null, 8, _hoisted_3)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.content.videoLink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_VideoContainer, {
            videoSrc: _ctx.content.videoLink
          }, null, 8, ["videoSrc"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.content[_ctx.locale + 'Translation'].text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Translation, {
            data: _ctx.content,
            property: "text"
          }, null, 8, ["data"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.content.link)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Btn, {
            is: "a",
            class: "gossamer px-6 py-5 is-size-5",
            href: _ctx.content.link,
            target: "_blank",
            rel: "noopener noreferrer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Translation, {
                data: _ctx.content,
                property: "linkDescription"
              }, null, 8, ["data"])
            ]),
            _: 1
          }, 8, ["href"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}