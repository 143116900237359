
import { defineComponent } from "vue";
import Spinner from "./Spinner.vue";
import ImageZoom from "./ImageZoom.vue";

export default defineComponent({
    name: "FullScreenImageModal",
    components: { Spinner, ImageZoom },
    emits: ["close"],
    props: {
        image: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        const modal = this.$refs.modal as HTMLDivElement;
        modal.focus();
        document.body.classList.add("modal-open");
    },
    beforeUnmount() {
        document.body.classList.remove("modal-open");
    },
});
