
import { computed, defineComponent, PropType } from "vue";
import { ArtistDto, ArtworkDto } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Copyright",
    props: {
        artists: {
            type: Array as PropType<ArtistDto[]>,
            default: () => [],
        },
        artwork: {
            type: Object as PropType<ArtworkDto>,
        },
    },
    setup(props) {
        const { locale } = useI18n();

        const defaultCopyright = computed(() => {
            if (!props.artists.length) return "";
            const visda = props.artists.map((a) => `© ${a.fullName} / VISDA`);
            return visda.length > 1 ? visda.join(", ") : visda[0];
        });

        const customCopyright = computed(() => {
            const property = (locale.value + "CopyrightText") as "daCopyrightText" | "enCopyrightText";
            return props.artwork ? props.artwork[property] : "";
        });

        return { defaultCopyright, customCopyright };
    },
});
