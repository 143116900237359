
import { computed, defineComponent } from "vue";
import { useThemesResource, usePeriodsResource } from "@kunsten/core";
import Block from "@/shared/components/Block.vue";
import Translation from "@/shared/components/Translation.vue";

export default defineComponent({
    name: "ArtworkPageThemesAndPeriods",
    components: { Block, Translation },
    props: {
        artworkId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { entities: periods } = usePeriodsResource({
            page: "1",
            pagination: "false",
            "artworks.id": props.artworkId,
            "order[sortNumber]": "asc",
        });

        const { entities: themes } = useThemesResource({
            page: "1",
            pagination: "false",
            "artworks.id": props.artworkId,
            "order[sortNumber]": "asc",
        });

        const entities = computed(() => {
            const themesMapped = themes.value.map((theme) => {
                return {
                    "@id": theme["@id"],
                    id: theme.id,
                    daTranslation: theme.daTranslation,
                    enTranslation: theme.enTranslation,
                    routerName: "ThemeDetails",
                };
            });

            const periodsMapped = periods.value.map((period) => {
                return {
                    "@id": period["@id"],
                    id: period.id,
                    daTranslation: period.daTranslation,
                    enTranslation: period.enTranslation,
                    routerName: "PeriodDetails",
                };
            });
            return periodsMapped.concat(themesMapped);
        });

        return { entities };
    },
});
