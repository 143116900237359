
import { defineComponent, PropType, ref } from "vue";
import { ArtworkDto, useArtworks } from "@kunsten/core";
import LabelledText from "../../../shared/components/LabelledText.vue";
import Date from "../../../shared/components/Date.vue";
import Taxonomy from "../../../shared/components/Taxonomy.vue";
import Taxonomies from "../../../shared/components/Taxonomies.vue";
import Translation from "../../../shared/components/Translation.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "ArtworkPageDetails",
    components: { LabelledText, Date, Taxonomy, Taxonomies, Translation },
    props: {
        artwork: {
            type: Object as PropType<ArtworkDto>,
            required: true,
        },
    },
    setup(props) {
        const { getEsArtwork } = useArtworks();
        const creatorsNames = ref([] as string[]);
        const { locale } = useI18n();

        getEsArtwork(props.artwork.id, { lang: locale.value }).then((res) => {
            creatorsNames.value = res.data.creatorsNames;
        });

        return { creatorsNames };
    },
});
