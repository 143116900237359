
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "Btn",
    props: {
        is: {
            type: String as PropType<"a" | "button">,
            default: "button",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "button",
        },
    },
    methods: {
        handleClick(ev: MouseEvent) {
            if (this.is === "a" && this.disabled) {
                ev.preventDefault();
                ev.stopImmediatePropagation();
                return;
            }

            this.$emit("click", ev);
        },
    },
});
