
import { BREAKPOINTS, useViewport } from "@kunsten/core";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ImageZoom",
    props: {
        image: String,
    },
    data() {
        return {
            width: window.innerWidth > 768 ? window.innerWidth - 6 * 32 : window.innerWidth - 32,
            height: window.innerHeight - 4 * 32,
            imageHMTL: new Image(),
            imageOverviewHMTL: new Image(),
            scale: 1.0,
            dragX: 0,
            dragY: 0,
            pinchX: 0,
            pinchY: 0,
            dragging: false,
            pinching: false,
            currentTop: 0,
            currentLeft: 0,
        };
    },
    setup() {
        const { matches: isDesktop } = useViewport(BREAKPOINTS.desktop);

        return { isDesktop };
    },
    mounted() {
        this.onResize = this.onResize.bind(this);
        document.addEventListener("resize", this.onResize, { passive: true });

        this.imageHMTL.src = `${this.image}/download?width=${this.width}&height=${this.height}`;
        this.imageOverviewHMTL.src = `${this.image}/download?width=${250}&height=${(
            (this.height / this.width) *
            250
        ).toFixed(0)}`;

        this.imageHMTL.onload = () => {
            this.$emit("loaded");
            this.draw(0.0);
        };
    },
    beforeUnmount() {
        document.removeEventListener("resize", this.onResize);
    },
    methods: {
        onTouchDown(event: TouchEvent) {
            if (!this.isDesktop && event.touches) {
                this.dragX = event.touches.item(0)?.pageX || 0;
                this.dragY = event.touches.item(0)?.pageY || 0;
                this.pinchX = event.touches.item(1)?.pageX || 0;
                this.pinchY = event.touches.item(1)?.pageY || 0;

                if (event.touches.length == 1) this.dragging = true;
                if (event.touches.length == 2) this.pinching = true;
            }
        },
        onTouchMove(event: TouchEvent) {
            if (!this.isDesktop && event.touches?.length) {
                const lastX = event.touches.item(0)?.pageX || 0;
                const lastY = event.touches.item(0)?.pageY || 0;
                const lastPinchX = event.touches.item(1)?.pageX || 0;
                const lastPinchY = event.touches.item(1)?.pageY || 0;
                if (this.dragging) this.move(lastX - this.dragX, lastY - this.dragY);
                if (this.pinching) {
                    const deltaStart = Math.abs(this.dragY - this.pinchY);
                    const deltaEnd = Math.abs(lastY - lastPinchY);
                    deltaStart < deltaEnd ? this.touchZoomIn() : this.touchZoomOut();
                }
                this.dragX = lastX;
                this.dragY = lastY;
                this.pinchX = lastPinchX;
                this.pinchY = lastPinchY;
            }
        },
        onTouchUp() {
            if (!this.isDesktop) {
                this.dragging = false;
                this.pinching = false;
            }
        },
        onMouseDown(event: MouseEvent) {
            this.dragX = event.offsetX;
            this.dragY = event.offsetY;
            this.dragging = true;
        },
        onMouseMove(event: MouseEvent) {
            if (this.dragging) {
                const lastX = event.offsetX;
                const lastY = event.offsetY;

                this.move(lastX - this.dragX, lastY - this.dragY);

                this.dragX = lastX;
                this.dragY = lastY;
            }
        },
        onMouseUp() {
            this.dragging = false;
        },
        onResize(): void {
            this.width = window.innerWidth > 768 ? window.innerWidth - 6 * 32 : window.innerWidth - 32;
            this.height = window.innerHeight - 4 * 32;
        },
        onMouseWheel(event: WheelEvent) {
            event.deltaY < 0 ? this.zoomIn() : this.zoomOut();
        },
        zoomIn() {
            this.draw(0.1);
        },
        zoomOut() {
            this.draw(-0.1);
        },
        touchZoomIn() {
            this.draw(0.025);
        },
        touchZoomOut() {
            this.draw(-0.025);
        },
        move(x: number, y: number) {
            this.currentTop += y / this.scale;
            this.currentLeft += x / this.scale;

            this.draw(0.0);
        },
        // Fasten your seatbelts before scrolling down
        draw(scaleChange: number) {
            if (this.scale + scaleChange < 1.0) return;

            this.scale += scaleChange;

            const canvas = this.$refs.canvas as HTMLCanvasElement;
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

            const imageWidth = this.imageHMTL.width;
            const imageHeight = this.imageHMTL.height;

            this.resizeCanvas(canvas, imageWidth, imageHeight);

            // clear canvas
            ctx.clearRect(0, 0, imageWidth, imageHeight);

            // move left / right & top / bottom
            // ctx.translate(this.currentLeft, this.currentTop);
            // the same as commented but using transform
            ctx.transform(1.0, 0, 0, 1.0, this.currentLeft, this.currentTop);

            // scale and center
            // Example
            // image size -> 500x500, scale -> 1.05
            // 500 * 1.05 = 525 -> new size 525x525
            // 525 - 500 = 25 -> size increased by 25 px
            // 25 / 2 = 12.5 -> center has been moved by 12.5 to right and bottom
            // center of the image needs to be moved (-12.5, -12.5)
            let centerX = -1 * ((this.scale * imageWidth - imageWidth) / 2);
            let centerY = -1 * ((this.scale * imageHeight - imageHeight) / 2);
            // apply translations
            centerX += this.currentLeft * this.scale - this.currentLeft;
            centerY += this.currentTop * this.scale - this.currentTop;

            // ctx.transform (x scale, y skewing, x skewing, y scale, x translate, y translate)
            ctx.transform(this.scale, 0, 0, this.scale, centerX, centerY);

            // draw image
            ctx.drawImage(this.imageHMTL, 0, 0, imageWidth, imageHeight);

            // draw image overview with rectangle
            this.drawOverview();
            this.drawOverviewRectangle(ctx.getTransform(), imageWidth, imageHeight);
        },
        drawOverview() {
            const canvas = this.$refs.canvasOverview as HTMLCanvasElement;
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

            const imageWidth = this.imageOverviewHMTL.width;
            const imageHeight = this.imageOverviewHMTL.height;

            this.resizeCanvas(canvas, imageWidth, imageHeight);

            ctx.clearRect(0, 0, imageWidth, imageHeight);
            ctx.drawImage(this.imageOverviewHMTL, 0, 0, imageWidth, imageHeight);
        },
        drawOverviewRectangle(transform: DOMMatrix, imageWidth: number, imageHeight: number) {
            const canvas = this.$refs.canvasOverview as HTMLCanvasElement;
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

            const imageOverviewWidth = this.imageOverviewHMTL.width;
            const imageOverviewHeight = this.imageOverviewHMTL.height;

            // transform: DOMMatrix
            // they could have named variables better...
            // a - x scale
            // b - y skewing
            // c - x skewing
            // d - y scale
            // e - x translate
            // f - y translate

            const scaleX = transform.a;
            const scaleY = transform.d;
            const translateX = transform.e;
            const translateY = transform.f;

            // Overview rectangle width & height
            // Example
            // Overview image is 250x250
            // Image 500x500 has been scaled with x1.1
            // 500 x 1.1 = 550 and 500 x 1.1 = 550
            // What part of the new image is old image ?
            // 500 / 550 = 0.90909...
            // Then overview rectangle width and height equals 250 * (250 / (250 * 1.1)) = 227.272...

            const width = imageOverviewWidth * (imageOverviewWidth / (imageOverviewWidth * scaleX));
            const height = imageOverviewHeight * (imageOverviewHeight / (imageOverviewHeight * scaleY));

            // Overview rectangle x & y
            // Example
            // Overview image is 250x250
            // Image 500x500 has been translated by -50px left and top
            // Then we need to calculate proportions 250 / 500 = 0.5
            // So, overview rectangle needs to be moved by -50px * 0.5 = -25px
            // Also it needs to be multipled by -1, because rectangle is moving
            // in opposite direction to original image translation
            // Finally we need to apply scale, so we can use knowledge from problem above

            const x =
                -1 *
                translateX *
                (imageOverviewWidth / imageWidth) *
                (imageOverviewWidth / (imageOverviewWidth * scaleX));
            const y =
                -1 *
                translateY *
                (imageOverviewHeight / imageHeight) *
                (imageOverviewHeight / (imageOverviewHeight * scaleY));

            // Now, we can draw overview rectangle
            ctx.beginPath();
            ctx.rect(x, y, width, height);
            ctx.fillStyle = "#33333399";
            ctx.fill();
            ctx.lineWidth = 2;
            ctx.strokeStyle = "white";
            ctx.stroke();
        },
        clear() {
            // reset all to initial values
            this.scale = 1.0;
            this.dragX = 0;
            this.dragY = 0;
            this.dragging = false;
            this.currentTop = 0;
            this.currentLeft = 0;

            // redraw image
            this.draw(0.0);
        },
        resizeCanvas(canvas: HTMLCanvasElement, width: number, height: number) {
            canvas.width = width;
            canvas.height = height;
        },
    },
});
