import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bcbe0cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "artwork-page-exhibitions pt-6 pb-3",
  "aria-labelledby": "artwork-page-exhibitions-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { id: "artwork-page-exhibitions-heading" }
const _hoisted_4 = { class: "columns is-multiline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExhibitionBlock = _resolveComponent("ExhibitionBlock")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("artwork.exhibitions")), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
              return (_openBlock(), _createBlock(_component_ExhibitionBlock, {
                class: "column is-half-tablet is-one-third-desktop mb-6",
                key: entity['@id'],
                entity: entity
              }, null, 8, ["entity"]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}