import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87eb9378"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "artwork-page-details py-6",
  "aria-labelledby": "artwork-page-details-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { id: "artwork-page-details-heading" }
const _hoisted_4 = { class: "artwork-page-details-container" }
const _hoisted_5 = { class: "artwork-page-details-container-left" }
const _hoisted_6 = { class: "artwork-page-details-container-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelledText = _resolveComponent("LabelledText")!
  const _component_Date = _resolveComponent("Date")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Taxonomy = _resolveComponent("Taxonomy")!
  const _component_Taxonomies = _resolveComponent("Taxonomies")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("artwork.details.header")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.artist")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.creatorsNames.join(", ")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.title")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.artwork.title || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.translated-title")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.artwork.translatedTitle || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.production-date")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Date, {
                format: "year",
                date: _ctx.artwork.productionDate
              }, null, 8, ["date"])
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.on-display")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.artwork.onDisplay ? _ctx.$t("general.yes") : _ctx.$t("general.no")), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.dimensions")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Translation, {
                data: _ctx.artwork,
                property: "dimensions"
              }, null, 8, ["data"])
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.inventory-number")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.artwork.inventoryNumber || "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.acquisition-reason")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Translation, {
                data: _ctx.artwork,
                property: "acquisitionReason"
              }, null, 8, ["data"])
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.artType")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Taxonomy, {
                type: "art_types",
                id: _ctx.artwork.artType
              }, null, 8, ["id"])
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.materials")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Taxonomies, {
                type: "materials",
                taxonomies: _ctx.artwork.materials
              }, null, 8, ["taxonomies"])
            ]),
            _: 1
          }),
          _createVNode(_component_LabelledText, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("artwork.details.technique")) + ":", 1)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Taxonomy, {
                type: "techniques",
                id: _ctx.artwork.technique
              }, null, 8, ["id"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}