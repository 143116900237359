
import { defineComponent, PropType } from "vue";
import ArtworkPageSingleVideoContent from "./ArtworkPageSingleVideoContent.vue";
import { ArtworkContentDto } from "@kunsten/core";

export default defineComponent({
    name: "ArtworkPageVideoContents",
    components: { ArtworkPageSingleVideoContent },
    props: {
        contents: {
            type: Array as PropType<ArtworkContentDto[]>,
            default: () => [],
        },
    },
    computed: {
        placements() {
            let left = false;

            return this.contents.map((item) => {
                if (!item.fullWidth) {
                    left = !left;
                } else {
                    left = false;
                }
                return item.fullWidth ? "center" : left ? "left" : "right";
            });
        },
    },
});
