
import { defineComponent, PropType } from "vue";
import VideoContainer from "../../../shared/components/VideoContainer.vue";
import TranslationAlt from "../../../shared/components/TranslationAlt.vue";
import Translation from "../../../shared/components/Translation.vue";
import Btn from "../../../shared/components/Btn.vue";
import { ArtworkContentDto } from "@kunsten/core";
import { useI18n } from "vue-i18n";

type Placement = "center" | "left" | "right";

export default defineComponent({
    name: "ArtworkPageSingleVideoContent",
    components: { VideoContainer, Translation, TranslationAlt, Btn },
    props: {
        content: {
            type: Object as PropType<ArtworkContentDto>,
            required: true,
        },
        placement: {
            type: String as PropType<Placement>,
            default: "center",
        },
    },
    setup() {
        const { locale } = useI18n();

        return { locale };
    },
});
