import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75916467"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "artwork-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArtworkPageContent = _resolveComponent("ArtworkPageContent")!
  const _component_ArtworkPageDetails = _resolveComponent("ArtworkPageDetails")!
  const _component_ArtworkPageExhibitions = _resolveComponent("ArtworkPageExhibitions")!
  const _component_ArtworkPageVideoContents = _resolveComponent("ArtworkPageVideoContents")!
  const _component_ArtworkPageRelated = _resolveComponent("ArtworkPageRelated")!
  const _component_ArtworkPageRelatedCollections = _resolveComponent("ArtworkPageRelatedCollections")!
  const _component_ArtworkPageThemesAndPeriods = _resolveComponent("ArtworkPageThemesAndPeriods")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ArtworkPageContent, { artwork: _ctx.artwork }, null, 8, ["artwork"]),
        _createVNode(_component_ArtworkPageDetails, { artwork: _ctx.artwork }, null, 8, ["artwork"]),
        _createVNode(_component_ArtworkPageExhibitions, {
          artworkId: _ctx.artwork.id
        }, null, 8, ["artworkId"]),
        _createVNode(_component_ArtworkPageVideoContents, {
          contents: _ctx.artwork.contents
        }, null, 8, ["contents"]),
        (_ctx.artwork.relatedArtworks.length)
          ? (_openBlock(), _createBlock(_component_ArtworkPageRelated, {
              key: 0,
              artworks: _ctx.artwork.relatedArtworks
            }, null, 8, ["artworks"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ArtworkPageRelatedCollections, {
          artworkId: _ctx.artwork.id
        }, null, 8, ["artworkId"]),
        _createVNode(_component_ArtworkPageThemesAndPeriods, {
          artworkId: _ctx.artwork.id
        }, null, 8, ["artworkId"])
      ]))
    : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
}