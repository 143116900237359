import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61ca1542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kunsten-copyright" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.artwork.copyright)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.customCopyright), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.defaultCopyright), 1))
  ]))
}