
import { defineComponent, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useDocumentTitle, useSingleResource } from "@kunsten/core";

import ArtworkPageContent from "../shared/ArtworkPageContent.vue";
import ArtworkPageDetails from "../shared/ArtworkPageDetails.vue";
import ArtworkPageRelated from "../shared/ArtworkPageRelated.vue";
import ArtworkPageExhibitions from "../shared/ArtworkPageExhibitions.vue";
import ArtworkPageVideoContents from "../shared/ArtworkPageVideoContents.vue";
import ArtworkPageThemesAndPeriods from "../shared/ArtworkPageThemesAndPeriods.vue";
import ArtworkPageRelatedCollections from "../shared/ArtworkPageRelatedCollections.vue";

import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";

export default defineComponent({
    name: "ArtworkPage",
    components: {
        ArtworkPageContent,
        ArtworkPageDetails,
        ArtworkPageRelated,
        ArtworkPageExhibitions,
        ArtworkPageVideoContents,
        ArtworkPageThemesAndPeriods,
        ArtworkPageRelatedCollections,
        SpinnerContainer,
    },
    setup() {
        const route = useRoute();
        const artworkId = computed(() => route.params.id as string);
        const entityName = computed(() => "artworks");
        const { entity, loading, error } = useSingleResource(entityName, artworkId);
        const { t } = useI18n();
        const { setTitle } = useDocumentTitle(t("general.base-title"));

        watch(
            entity,
            () => {
                if (entity.value?.title) {
                    setTitle(entity.value.title as string);
                } else {
                    setTitle();
                }
            },
            { deep: true }
        );

        return { artwork: entity, loading, error };
    },
});
