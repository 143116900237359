
import { defineComponent, PropType } from "vue";
import { UserCollectionDto } from "@kunsten/core";
import CollectionBlock from "./CollectionBlock.vue";
import Slider from "./Slider.vue";

export default defineComponent({
    name: "CollectionSlider",
    components: { Slider, CollectionBlock },
    props: {
        entities: {
            type: Array as PropType<UserCollectionDto[]>,
            default: () => [],
        },
    },
});
