
import { defineComponent, PropType } from "vue";
import { ArtworkDto } from "@kunsten/core";
import Gallery from "../../../shared/components/Gallery.vue";
import ArtworkPageInfo from "./ArtworkPageInfo.vue";

export default defineComponent({
    name: "ArtworkPageContent",
    components: { Gallery, ArtworkPageInfo },
    props: {
        artwork: {
            type: Object as PropType<ArtworkDto>,
            required: true,
        },
    },
    data() {
        return {
            openModal: false,
        };
    },
    methods: {
        print() {
            window.print();
        },
    },
});
