
import { KunstenDateFormat, useFormattedEsDate } from "@kunsten/core";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "EsDate",
    props: {
        startDate: String,
        endDate: String,
        format: {
            type: String as PropType<KunstenDateFormat>,
            default: "default",
        },
        period: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { formattedEsDate } = useFormattedEsDate(props.startDate, props.endDate, props.format, props.period);

        return { formattedEsDate };
    },
});
