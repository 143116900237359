
import { defineComponent, PropType } from "vue";
import Spinner from "./Spinner.vue";
import FullScreenImageModal from "./FullScreenImageModal.vue";

export default defineComponent({
    name: "Gallery",
    components: { Spinner, FullScreenImageModal },
    props: {
        images: {
            type: Array as PropType<string[]>,
            required: true,
            default: () => [],
        },
        modal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            current: 0,
            highlightIndex: 0,
            loading: true,
        };
    },
    mounted() {
        if (!this.images.length) return;
        // https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded
        Promise.all(
            Array.from(this.$el.querySelectorAll("img.gallery-image") as HTMLImageElement[])
                .filter((img) => !img.complete)
                .map(
                    (img) =>
                        new Promise((resolve) => {
                            img.onload = img.onerror = resolve;
                        })
                )
        ).then(() => {
            (this.$refs.container as HTMLDivElement).scrollLeft = 0;
            this.loading = false;
        });
    },
    methods: {
        prev() {
            if (this.current > 0) {
                this.current--;
                this.move();
            }
        },
        next() {
            if (this.current < this.images.length - 1) {
                this.current++;
                this.move();
            }
        },
        display(index: number) {
            this.current = index;
            this.move();
        },
        isMultiple(): boolean {
            return this.images.length > 1;
        },
        move() {
            const boxWidth = (this.$refs.container as HTMLDivElement).scrollWidth / this.images.length;
            const scrollTo = boxWidth * this.current;
            (this.$refs.container as HTMLDivElement).scrollLeft = scrollTo;
        },
        onScroll(e: Event) {
            const position = (e.currentTarget as HTMLDivElement).scrollLeft;
            const boxWidth = (this.$refs.container as HTMLDivElement).scrollWidth / this.images.length;
            this.current = Math.floor((position + boxWidth / 2) / boxWidth);
            this.highlightIndex = this.current;
        },
    },
});
