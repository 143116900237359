
import { defineComponent, PropType } from "vue";
import Taxonomy from "./Taxonomy.vue";

export default defineComponent({
    name: "Taxonomies",
    components: { Taxonomy },
    props: {
        type: {
            type: String,
            required: true,
        },
        taxonomies: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
});
