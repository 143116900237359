import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5beebb61"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "artwork-page-related pt-6",
  "aria-labelledby": "artwork-page-related-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { id: "artwork-page-related-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArtworkSlider = _resolveComponent("ArtworkSlider")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("artwork.related-artworks")), 1),
      _createVNode(_component_ArtworkSlider, { entities: _ctx.relatedArtworks }, null, 8, ["entities"])
    ])
  ]))
}