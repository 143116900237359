import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b81459e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "artwork-page-themes-and-periods py-6",
  "aria-labelledby": "artwork-page-themes-and-periods-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { id: "artwork-page-themes-and-periods-heading" }
const _hoisted_4 = { class: "columns is-multiline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("artwork.related-periods-and-themes")), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
              return (_openBlock(), _createElementBlock("div", {
                key: entity['@id'],
                class: "column is-half-tablet is-one-quarter-desktop"
              }, [
                _createVNode(_component_router_link, {
                  to: { name: entity.routerName, params: { id: entity.id } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Block, {
                      class: "px-4 py-2",
                      bold: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Translation, {
                          data: entity,
                          property: "title"
                        }, null, 8, ["data"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}