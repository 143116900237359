
import { defineComponent } from "vue";
import { useUserCollectionsResource } from "@kunsten/core";
import CollectionSlider from "../../../shared/components/CollectionSlider.vue";

export default defineComponent({
    name: "ArtworkPageRelatedCollections",
    components: { CollectionSlider },
    props: {
        artworkId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { entities } = useUserCollectionsResource({
            page: "1",
            "artworks.artwork.id": props.artworkId,
        });

        return { entities };
    },
});
